<template>
  <app-modal-copy-business
    v-if="editMode"
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="detail"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.detail.title") }}
      </h1>
      <app-text-tooltip 
           :text="$t('business.structure.copy_from_existing_business')" >
        <app-icon 
          v-if="showCopyBusinessIcon"
          name="ClipboardCopyIcon"
          class="h-9 w-6 text-gray-300 cursor-pointer hover:text-gray-600"
          @click="showModalCopyBusiness = true"
          
        />
      </app-text-tooltip>
    </div>
    <p class="mt-4">
      {{ $t("business.detail.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessDetails"
      v-if="business.detail != undefined"
    >
      <div class="flex flex-col lg:flex-row mb-5 lg:mb-0">
        <div class="flex flex-col lg:w-7/12 w-full">
          <app-form-input
            id="business.detail.company_name"
            name="business.detail.company_name"
            type="text"
            :placeholder="$t('business.detail.company_placeholder')"
            :labelText="$t('business.detail.company_label')"
            class="mb-5"
            v-model="business.detail.company_name"
            :errorMessage="errors.company_name"
            @onblur="onCompanyNameFocus = false"
            @onfocus="onCompanyNameFocus = true"
            :disabled="!editMode"
          />
        </div>
        <app-form-info
          v-if="onCompanyNameFocus"
          :infoText="$t('business.detail.company_info')"
          class="lg:w-1/3 w-full"
        />
      </div>

      <app-form-input
        id="business.detail.company_reg_no"
        name="business.detail.company_reg_no"
        type="text"
        :placeholder="$t('business.detail.company_reg_placeholder')"
        :labelText="$t('business.detail.company_reg_no_label')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.detail.company_reg_no"
        :errorMessage="errors.company_reg_no"
        :disabled="!editMode"
      />

      <!-- <app-form-input
        id="business.detail.tax_id"
        name="business.detail.tax_id"
        type="text"
        :placeholder="$t('business.detail.tax_id')"
        :labelText="$t('business.detail.tax_id')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.detail.tax_id"
        :errorMessage="errors.tax_id"
        :disabled="!editMode"
      /> -->

      <app-form-company-annual-turnover
        :placeholder="$t('business.detail.annual_turnover_placeholder')"
        class="lg:w-7/12 w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.company_annual_turnover"
        :errorMessage="errors.company_annual_turnover_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="!editMode"
      />

      <app-form-input
        id="business.detail.business_activity"
        name="business.detail.business_activity"
        type="text"
        :placeholder="$t('business.detail.activity_placeholder')"
        :labelText="$t('business.detail.activity_label')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.detail.business_activity"
        :errorMessage="errors.business_activity"
        :disabled="!editMode"
      />

      <!-- Hide for temporary (for revisit https://app.clickup.com/t/2e0dnyj)
        
        <app-form-input
        id="business.detail.address_1"
        name="business.detail.address_1"
        class="lg:w-7/12 w-full"
        type="text"
        :placeholder="$t('general.address.line_1')"
        :labelText="$t('business.structure.address_label')"
        v-model="business.detail.address_1"
        :errorMessage="errors.address_1"
      />

      <app-form-input
        id="business.detail.address_2"
        name="business.detail.address_2"
        class="lg:w-7/12 w-full"
        type="text"
        :placeholder="$t('general.address.line_2')"
        v-model="business.detail.address_2"
        :errorMessage="errors.address_2"
      />

      <app-form-input
        id="business.detail.postcode"
        name="business.detail.postcode"
        class="lg:w-7/12 w-full"
        type="number"
        :placeholder="$t('general.address.postcode')"
        v-model="business.detail.postcode"
        :errorMessage="errors.postcode"
      />

      <app-form-input
        id="business.detail.city"
        name="business.detail.city"
        class="lg:w-7/12 w-full"
        type="text"
        :placeholder="$t('general.address.city')"
        v-model="business.detail.city"
        :errorMessage="errors.city"
      />

      <app-form-state
        :showLabel="false"
        class="lg:w-7/12 w-full mb-5"
        :placeholder="$t('general.address.state')"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.state"
        :errorMessage="errors.state_id"
      /> -->

      <app-form-telephone-no
        name="business.detail.phone_no"
        :labelText="$t('business.detail.phone_no_label')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.detail.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="!editMode"
      />

      <app-form-industries
        :labelText="$t('business.detail.industry_label')"
        :placeholder="$t('business.detail.industry_placeholder')"
        class="lg:w-7/12 w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.detail.industry"
        :errorMessage="errors.industry_id"
        :showLabel="true"
        :allowFilter="true"
        :disabled="!editMode"
      />

      <app-form-input
        id="business.detail.website"
        name="business.detail.website"
        class="lg:w-7/12 w-full mb-5"
        type="url"
        :labelText="$t('business.detail.website_label')"
        :placeholder="$t('business.detail.website_placeholder')"
        v-model="business.detail.website"
        :errorMessage="errors.website"
        :disabled="!editMode"
      />

      <div class="flex flex-col lg:flex-row mb-5">
        <div class="flex flex-col lg:w-7/12 w-full">
          <app-form-textarea
            id="business.detail.description"
            name="business.detail.description"
            type="text"
            :labelText="$t('business.detail.product_desc_label')"
            v-model="business.detail.description"
            :errorMessage="errors.description"
            @onblur="onProductDescritpionFocus = false"
            @onfocus="onProductDescritpionFocus = true"
            :disabled="!editMode"
          />
        </div>
        <app-form-info
          v-if="onProductDescritpionFocus"
          :infoText="$t('business.detail.product_desc_info')"
          class="lg:w-1/3 w-full"
        />
      </div>

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.next") }}</app-button
      >
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import appFormCompanyAnnualTurnover from "../../../components/form/reference/app-form-company-annual-turnover.vue";
export default {
  components: { appFormCompanyAnnualTurnover },
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      emptyErrors: {
        company_name: [],
        company_reg_no: [],
        company_annual_turnover: [],
        business_activity: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        state_id: [],
        phone_no: [],
        industry_id: [],
        website: [],
        description: [],
      },
      onCompanyNameFocus: false,
      onProductDescritpionFocus: false,
    };
  },

  mounted() {
    if (this.business.id == null) {
      this.$router.push({ path: "/business/structure" });
      return;
    }
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        this.all_business?.length > 0 &&
        statusBusinessAllow.includes(this.business.business_status_id)
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  methods: {
    copyBusiness(business) {
      let detail = business.detail;

      detail.id = this.business?.detail?.id ?? null;
      detail.business_id = this.business?.id ?? null;
      detail.company_name = business.detail.company_name;
      detail.company_reg_no = business.detail.company_reg_no;
      detail.company_annual_turnover = business.detail.company_annual_turnover;
      detail.business_activity = business.detail.business_activity;
      detail.address_1 = business.detail.address_1;
      detail.address_2 = business.detail.address_2;
      detail.postcode = business.detail.postcode;
      detail.city = business.detail.city;
      detail.state = business.detail.state;
      detail.phone_no = business.detail.phone_no;
      detail.industry = business.detail.industry;
      detail.website = business.detail.website;
      detail.description = business.detail.description;

      this.business.detail = detail;
    },

    async submitBusinessDetails() {
      this.resetState();

      const businessDetails = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessDetail",
        this.business.detail
      );

      if (businessDetails == null) return;

      this.$router.push({ path: "/business/representative" });
    },

    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
